<template>
  <div class="product py-5">
    <div v-if="product" class="container">
      <div class="row form-horizontal">
        <div class="col-md-7">
          <div class="row">
            <div class="col-12 col-md-12 main-image text-right">

              <img :src="img" class="img-fluid" @click="toggleLightBox(img)"/>


              <a v-if="product.video" class="play-video" @click.prevent="playVideo">
                <svg width="45px" height="45px" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><path d="M25 42c-9.4 0-17-7.6-17-17S15.6 8 25 8s17 7.6 17 17-7.6 17-17 17zm0-32c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15-6.7-15-15-15z"/><path d="M20 33.7V16.3L35 25l-15 8.7zm2-14v10.5l9-5.3-9-5.2z"/></svg>
              </a>
              <a class="zoom-image" @click.prevent="toggleLightBox(img)">

                <svg width="48px" height="48px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33 6H42V15" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M42 33V42H33" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 42H6V33" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 15V6H15" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </div>
            <div class="col-12 col-md-12">

              <div v-show="toggleVideoBox" id="video-container" @click.self="toggleVideoBox = false">
                <button class="vgs__close" @click="toggleVideoBox=false">×</button>
                <div class="video-content">
                  <video v-if="product.video" width="100%" height="600" controls>
                    <source :src="product.video" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              <vue-gallery-slideshow v-if="imageSlideshow.length > 0"
              :images="imageSlideshow" :index="selectedImage" @close="selectedImage = null"/>
              <div class="row">
                <div class="col-6 col-md-3">
                  <a href="#" class="thumb-image mt-2" @click.prevent="img = product.image;">
                    <div :style="'background-image: url('+product.image+')'"></div>
                  </a>
                </div>
                <div v-for="(eimg, i) in extra_images" :key="i" class="col-6 col-md-3">
                  <a href="#" class="thumb-image px-2 mt-2" @click.prevent="img = eimg;">
                    <div :style="'background-image: url('+eimg+')'"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="editing" class>
            <strong>Main image: </strong>
            <input type="file" class="form-control" accept="image/*" ref="image"/>
          </div>
          <div v-if="editing" class="mt-3">
            <strong>Glamour: </strong>
            <input type="text" class="form-control" v-model="update.glamour" />
            <input type="file" class="form-control" accept="image/*" ref="glamour"/>
          </div>
          <div v-if="editing" class="mt-3">
            <strong>Product Video: </strong>
            <input type="text" class="form-control" v-model="update.video" />
            <input type="file" class="form-control" accept="video/*" ref="video"/>
          </div>
          <div v-if="editing">
            <hr>
            <div class="text-muted">To Delete the image just clean the text field.</div>
            <div v-for="i in totalExtraImages" :key="i" class="mt-3">
              <strong>Other Image {{ i }}: </strong>
              <input type="text" class="form-control" v-model="update.extra_images[i-1]">
              <input type="file" class="form-control mt-2" accept="image/*" name="extra_images[]" />
            </div>
            <button class="btn btn-outline-primary mt-2" @click="totalExtraImages++">Add extra image</button>
          </div>
        </div>
        <div class="col-md-5">
          <div v-if="editing" class="mt-2">
            <strong>Name: </strong>
            <input type="text" class="form-control" v-model="update.name" />
          </div>
          <h1 v-else>{{ product.name }}</h1>
          <div v-if="product.hasOwnProperty('item_code')" class="mt-2">
            <strong>SKU: </strong>
            <input v-if="editing" type="text" class="form-control" v-model="update.item_code"/>
            <span v-else>{{ product.item_code }}</span>
          </div>
          <div v-if="product.hasOwnProperty('unbreakable_id')" class="mt-2">
            <strong>SKU: </strong>
            <input v-if="editing" type="text" class="form-control" v-model="update.unbreakable_id"/>
            <span v-else>{{ product.unbreakable_id }}</span>
          </div>
          <div v-if="product.hasOwnProperty('table_id')" class="mt-2">
            <strong>SKU: </strong>
            <input v-if="editing" type="text" class="form-control" v-model="update.table_id"/>
            <span v-else>{{ product.table_id }}</span>
          </div>
          <div v-if="product.hasOwnProperty('glass_id')" class="mt-2">
            <strong>SKU: </strong>
            <input v-if="editing" type="text" class="form-control" v-model="update.glass_id"/>
            <span v-else>{{ product.glass_id }}</span>
          </div>
          <div v-if="product.hasOwnProperty('barware_id')" class="mt-2">
            <strong>SKU: </strong>
            <input v-if="editing" type="text" class="form-control" v-model="update.barware_id"/>
            <span v-else>{{ product.barware_id }}</span>
          </div>
          <div v-if="product.brand || editing" class="mt-2">
            <strong>Brand: </strong>
            <input v-if="editing" type="text" class="form-control" v-model="update.brand" />
            <span v-else>{{ product.brand }}</span>
          </div>
          <div v-if="product.dimensions || editing" class="mt-2">
            <strong>Dimensions: </strong>
            <input v-if="editing" type="text" class="form-control" v-model="update.dimensions" />
            <span v-else>{{ product.dimensions }}</span>
          </div>
          <div v-if="product.description || editing" class="mt-2">
            <strong>Description: </strong>
            <textarea v-if="editing" class="form-control" v-model="update.description"></textarea>
            <span v-else v-html="product.description">
              {{ product.description }}
            </span>
          </div>
          <div v-if="product.features || editing" class="mt-2">
            <strong>Features: </strong>
            <input v-if="editing" type="text" class="form-control" v-model="update.features"/>
            <span v-else>{{ product.features }}</span>
          </div>
          <div v-if="product.case_pack || editing" class="mt-2">
            <strong>Case Pack: </strong>
            <input v-if="editing" type="text" class="form-control" v-model="update.case_pack"/>
            <span v-else>{{ product.case_pack }}</span>
          </div>
          <div v-if="editing" class="mt-2">
            <strong>Priority Product: </strong>
            <select class="form-control" v-model="update.priority">
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
          <div v-if="editing" class="mt-2">
            <strong>Tags: </strong>
            <input type="text" class="form-control" v-model="update.tag" />
          </div>
          <div class="mt-2"  v-if="!editing">
            <a @click.prevent="sample" href="#" class="btn btn-outline-primary mr-2">Sample Product</a>
            <a class="btn btn-outline-secondary active" @click="backToArchive">Back to {{ title }}</a>
<!--            <router-link v-if="burl" class="btn btn-outline-secondary active" :to="burl"></router-link>-->
          </div>
          <div v-if="admin && !editing" class="crud-options">
            <a class="btn btn-outline-info mr-3" href="#" @click.prevent="totalExtraImages = extra_images.length; editing = true">Edit</a>
            <a class="btn btn-outline-danger" href="#" @click.prevent="delRecord">Delete</a>
          </div>
          <div v-else-if="loading" class="my-3">
            <a href="#" class="btn-outline-warning">Loading...</a>
          </div>
          <div v-else-if="editing" class="mt-3">
            <a class="btn btn-outline-info mr-3" href="#" @click.prevent="save">Save</a>
            <a class="btn btn-outline-danger" href="#" @click.prevent="editing = false">Cancel</a>
          </div>
        </div>
      </div>
      <div v-if="editing" class="row mt-4">
        <div class="col-md-12">
          <strong>Similar: </strong>
          <v-select v-model="update.similar" multiple label="name" :filterable="false"  
          v-on:input="limiter"
          @search="fetchOptions" :options="options">
          </v-select>
        </div>
      </div>
      <div v-if="product.similar.length" class="row mt-5" >
        <div class="col-md-12">
          <h2>You also may like.</h2>
        </div>
        <div v-for="p in product.similar" :key="p._id" class="col-sm-12 col-md-6 col-lg-4 my-3">
          <router-link :to="url + '/' + p._id" class="products--item">
            <div class="products--item-image">
              <div
                v-if="p.glamour"
                class="products--item-overlay"
                :style="'background-image: url(' + p.glamour + ')'">
              </div>
              <img :src="p.image" :alt="p.name" class="img-fluid">
            </div>
            <h4 class="products--item-title text-center mt-3">{{ p.name }}</h4>
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="alert alert-info">
        Loading..
      </div>
    </div>
  </div>
</template>
<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
export default {
  name: "product",
  components: {VueGallerySlideshow},
  props: ["admin", "user", "cart"],
  watch: {
    $route() {
      this.load();
    }
  },
  data() {
    return {
      repository: "/products",
      db: false,
      img: false,
      loading: false,
      sampleloading: false,
      product: false,
      editing: false,
      update: false,
      options: [],
      similars: [],
      selectedImage:  null,
      totalExtraImages: 1,
      toggleVideoBox: false
    };
  },
  computed: {
    extra_images() {
      var images = [];

      if (this.product.extra_images && this.product.extra_images.length) {
        for(var i = 0; i < this.product.extra_images.length; i++) {
          if (this.product.extra_images[i]) {
            images.push(this.product.extra_images[i]);
          }
        }
      }

      return images;
    },
    imageSlideshow(){
      return [this.product.image, ...this.extra_images]
    },
    burl() {
      var urls = {
        products: "/",
        newproducts: "/",
        glasses: "/glassware",
        barwares: "/barware",
        unbreakables: "/eco",
        tablewares: "/tableware"
      };

      return urls[this.db];
    },
    url() {
      var urls = {
        products: "/products",
        newproducts: "/new-products",
        glasses: "/glassware",
        barwares: "/barware",
        unbreakables: "/eco",
        tablewares: "/tableware"
      };

      return urls[this.db];
    },
    fullurl() {
      return this.repository + "/" + this.$route.params.id + "?db=" + this.db;
    },
    title() {
      var urls = {
        products: "Home",
        newproducts: "New products",
        glasses: "Glassware",
        barwares: "Barware",
        unbreakables: "Unbreakable",
        tablewares: "Tableware"
      };

      return urls[this.db];
    }
  },
  methods: {
    sample() {
      if (this.user) {

        var cart
        if (this.cart)  {
          cart = this.cart;
        } else {
          cart = [];
        }

        var product = Object.assign({}, this.product);
        delete product.__v;
        delete product.similar;
        delete product.extra_images;
        product.db = this.db;
        product.url = this.url+'/'+product._id;
        
        var already = cart.find(p => (p.db == product.db && p._id == product._id));

        if (already) {
          window.toastr.warning('Product already in your cart');
        } else {
          cart.push(product);
          this.$emit("cartUpdated", cart);
          window.toastr.success('Your product is in your cart, continue with more products or go to your cart to proceess your sample.');
        }
      } else {
        window.$('#modal-login').modal();
        window.restart();
        window.$("#modal-login input").focus();
        window.$("#modal-login input").first().focus();
      }
    },
    fetchOptions(search, loading) {
      var path = this.repository + '?db=' + this.db + '&name[regex]=' + search;
      path += '&_id[ne]=' + this.$route.params.id;
      loading(true);
      window.$http.get(path).then(({ data }) => {
        this.options = data.data;
        setTimeout(function(){
          loading(false);  
        }, 200);
      })
    },
    limiter(e) {
      if(e.length > 3) {
        e.pop()
      }
    },
    delRecord() {
      var vm = this;

      window.bootbox.confirm(
        "Are you sure you want to delete this product!",
        function(result) {
          if (result) {
            window.$http.delete(vm.fullurl).then(() => {
              window.location = vm.url;
            });
          }
        }
      );
    },
    save() {
      var form_data = new FormData();
      for (var key in this.update) {
        if (key != 'similar') {
          form_data.append(key, this.update[key]);
        }
      }

      var similar = [];
      for (var i = 0, l = this.update.similar.length; i < l; i++) {
        similar.push(this.update.similar[i]._id);
      }

      form_data.append('similar', JSON.stringify(similar));

      if (this.$refs.image.files.length) {
        form_data.append("image", this.$refs.image.files[0]);
      }

      if (this.$refs.glamour.files.length) {
        form_data.append("glamour", this.$refs.glamour.files[0]);
      }

      if (this.$refs.video.files.length) {
        form_data.append("video", this.$refs.video.files[0]);
      }


      var images = $('[name="extra_images[]"]');
      for(var i = 0, l = images.length; i < l; i++) {
        if (images[i].files.length) {
          form_data.append('extraimages_'+i, images[i].files[0]);
        }
      }


      this.loading = true;
      window.$http.put(this.fullurl, form_data).then(() => {
        this.loading = false;
        this.editing = false;
        this.load();
      });
    },
    load() {
      this.db = this.$route.meta.db;
      this.product = false;
      this.similars = [];
      window.$http.get(this.fullurl).then(({ data }) => {
        this.update = Object.assign({}, data.data);
        delete this.update._id;
        delete this.update.created_at;
        delete this.update.__v;
        this.update.priority = this.update.priority || '0';
        this.product = data.data;
        this.img = this.product.image;
        this.totalExtraImages = this.product.extra_images ? this.product.extra_images.length : 0
      });
    },
    toggleLightBox(img){
      this.selectedImage =  this.imageSlideshow.findIndex(x=> x === img);
    },
    playVideo(){
      this.toggleVideoBox = true;
    },
    backToArchive(){
      const page = sessionStorage.getItem(this.db + '_page');
      const scrollPos = sessionStorage.getItem(this.db +'_scrollPos');

      const queryParams = {};

      if(page) queryParams['page'] = parseInt(page);
      if(scrollPos) queryParams['scrollPos'] = parseFloat(scrollPos);

      this.$router.push({path: this.burl, query: queryParams});
    }
  },
  mounted() {
    this.load();
  }
};
</script>

<style lang="scss">
#video-container{

  background:rgba(0,0,0,0.8);

  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:99999;
  .video-content{

    width:1240px;
    margin:0 auto;
    margin-top:6%;
    @media(max-width:1240px){
      max-width:100%;
    }
  text-align:center;
    video{
      max-height:70vh;
      max-width:900px;
      margin:0 auto!important;
      @media(max-width:900px){
        max-width:95%;
      }
    }
  }

}

.main-image{
  cursor:pointer;
}
.zoom-image, .play-video{

  svg{
    transition: .5s all;

    cursor:pointer;
    width:24px;
    &:hover{
      transform: scale(1.1);
    }
  }
}
.play-video{
  svg{
    width:40px;
  }
}
.vgs__next, .vgs__prev{
  background-color:rgba(0,0,0,0.3)!important;
  height:70px!important;
}
</style>
