<template>
  <div class="container py-5 mt-4">
    
    <form @submit.prevent="submit" class="needs-validation" ref="form">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="row">
          <div v-if="alert" class="alert alert-danger">{{alert}}</div>
            <h3>Reset your Password.</h3>
            <div class="col-md-12">
              <div class="form-group bmd-form-group">
                <label for="password" class="bmd-label-floating">New Password</label>
                <input type="password" class="form-control" v-model="account.password" id="password" required autocomplete="off" autofill="off"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group bmd-form-group">
                <label for="confirm" class="bmd-label-floating">Confirm Password</label>
                <input type="password" class="form-control" v-model="account.confirm" id="confirm" required autocomplete="off" autofill="off"/>
              </div>
            </div>
            <div class="col-md-12">
              <button type="submit" class="btn btn-success active">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script type="text/javascript">
  import axios from "axios";
  export default {
    name: "reset_password",
    data() {
      return {
        alert: false,
        account: {
          password: '',
          confirm: '',
        }
      };
    },
    methods: {
      submit() {
        var vm = this;
        this.alert = false;
        if (this.account.password == this.account.confirm) {
          let token = this.$route.params.token;
          window.$http.put("/auth/resetpassword/"+token, this.account).then(response => {
            if (response.data.success) {
              vm.alert = false;
              window.toastr.info('Your password has been updated. Login to continue');
              vm.$router.push('/');
            } else {
              vm.alert = "The link is not longer valid.";              
            }
          });
        } else {
          this.alert = "The passwords doesn't match";
        }
      }
    },
    mounted() {
      window.restart();
    }
  };
</script>