<template>
  <div class="tailormade">
    <div v-if="admin" class="editor-bar">
      <div v-if="!editing" class="crud-options">
        <a class="btn btn-outline-info mr-3" href="#" @click.prevent="editing = true">Edit</a>
      </div>
      <div v-else-if="loading">
        <a href="#" class="btn-outline-warning">Loading...</a>
      </div>
      <div v-else-if="editing">
        <a class="btn btn-outline-info mr-3" href="#" @click.prevent="save">Save</a>
        <a class="btn btn-outline-danger" href="#" @click.prevent="editing = false">Cancel</a>
      </div>
    </div>

    <div class="sustainability--banner">
      <img :src="main.banner" class="img-fluid" />
      <div v-if="editing" class="form-group container mt-3">
        <label>Banner</label>
        <input type="file" ref="image" accept="image/*" class="form-control" />
      </div>
    </div>

    <div class="container">
      <div class="row py-4">
        <div class="col-md-12 text-center">
          <div v-if="editing" class="form-group">
            <label>Description</label>
            <textarea class="form-control" v-model="update.content.description"></textarea>
          </div>
          <h3 v-else v-html="main.content.description"></h3>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="tailormade--tabs">
        <ul class="nav nav-fill justify-content-center py-2">
          <li class="nav-item">
            <a data-toggle="tab" href="#glassware" class="nav-link active">Tailor Made Glassware</a>
          </li>
          <li class="nav-item">
            <a data-toggle="tab" href="#drinkware" class="nav-link">Tailor Made Drinkware</a>
          </li>
          <li class="nav-item">
            <a data-toggle="tab" href="#tableware" class="nav-link">Tailor Made Tableware</a>
          </li>
        </ul>
      </div>
      <div class="tailormade--tabs-content tab-content">
        <div id="glassware" class="tab-pane active">
          <div v-if="editing" class="form-group">
            <label>Text</label>
            <textarea class="form-control" v-model="update.content.glassware"></textarea>
          </div>
          <h3 v-else class="font-weight-light mb-3" v-html="main.content.glassware"></h3>
          <div v-if="editing" class="form-group">
            <label>Image:</label>
            <input type="file" accept="image/*" ref="image_glassware" class="form-control" />
          </div>
          <img v-else :src="main.content['img-glassware']" class="img-fluid mx-auto my-3 d-block" />
        </div>
        <div id="drinkware" class="tab-pane">
          <div v-if="editing" class="form-group">
            <label>Text</label>
            <textarea class="form-control" v-model="update.content.drinkware"></textarea>
          </div>
          <h3 v-else class="font-weight-light mb-3" v-html="main.content.drinkware"></h3>
          <div v-if="editing" class="form-group">
            <label>Image:</label>
            <input type="file" accept="image/*" ref="image_drinkware" class="form-control" />
          </div>
          <img v-else :src="main.content['img-drinkware']" class="img-fluid mx-auto my-3 d-block" />
        </div>
        <div id="tableware" class="tab-pane">
          <div v-if="editing" class="form-group">
            <label>Text</label>
            <textarea class="form-control" v-model="update.content.tableware"></textarea>
          </div>
          <h3 v-else class="font-weight-light mb-3" v-html="main.content.tableware"></h3>
          <div v-if="editing" class="form-group">
            <label>Image:</label>
            <input type="file" accept="image/*" ref="image_tableware" class="form-control" />
          </div>
          <img v-else :src="main.content['img-tableware']" class="img-fluid mx-auto my-3 d-block" />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row py-4">
        <div class="col-md-5">
          <div v-if="editing" class="form-group">
            <label>Image 1:</label>
            <input type="file" accept="image/*" ref="image_left1" class="form-control" />
          </div>
          <img v-else :src="main.content['img-left1']" class="img-fluid" />
          <div v-if="editing" class="form-group">
            <label>Image 2:</label>
            <input type="file" accept="image/*" ref="image_left2" class="form-control" />
          </div>
          <img v-else :src="main.content['img-left2']" class="img-fluid" />
        </div>
        <div class="col-md-7">
          <h1 class="text-center mb-5 font-weight-light">Options & Capabilities:</h1>
          <div v-if="editing" class="form-group">
            <label>Title 1</label>
            <input type="text" class="form-control" v-model="update.content.title1"/>
          </div>
          <h2 class="text-center mt-4 mb-3 font-weight-light" v-else v-html="main.content.title1"></h2>

          <div v-if="editing" class="form-group">
            <label>Text 1</label>
            <textarea class="form-control" v-model="update.content.text1"></textarea>
          </div>
          <h3 class="font-weight-light" v-else v-html="main.content.text1"></h3>

          <div v-if="editing" class="form-group">
            <label>Title 2</label>
            <input type="text" class="form-control" v-model="update.content.title2"/>
          </div>
          <h2 class="text-center mt-4 mb-3 font-weight-light" v-else v-html="main.content.title2"></h2>

          <div v-if="editing" class="form-group">
            <label>Text 2</label>
            <textarea class="form-control" v-model="update.content.text2"></textarea>
          </div>
          <h3 class="font-weight-light" v-else v-html="main.content.text2"></h3>

          <div v-if="editing" class="form-group">
            <label>Title 3</label>
            <input type="text" class="form-control" v-model="update.content.title3"/>
          </div>
          <h2 class="text-center mt-4 mb-3 font-weight-light" v-else v-html="main.content.title3"></h2>


          <div v-if="editing" class="form-group">
            <label>Text 3</label>
            <textarea class="form-control" v-model="update.content.text3"></textarea>
          </div>
          <h3 class="font-weight-light" v-else v-html="main.content.text3"></h3>
        </div>
      </div>
    </div>

    <div class="container">
      <h3 class="font-weight-light text-center mb-3">
        Fill out the form below to find out how Tailor Made can create glassware and tableware that is truly yours.
      </h3>
      <contact-form></contact-form>
    </div>
  </div>
</template>
<script>
import ContactForm from "../components/ContactForm";
export default {
  name: "tailormade",
  props: ["admin"],
  components: {
    ContactForm
  },
  data() {
    return {
      main: {
        content: {}
      },
      editing: false,
      loading: false,
      update: false
    };
  },
  methods: {
    save() {
      var form_data = new FormData();
      for (var key in this.update) {
        if (key == "content") {
          form_data.append(key, JSON.stringify(this.update[key]));
        } else {
          form_data.append(key, this.update[key]);
        }
      }
      if (this.$refs.image.files.length) {
        form_data.append("image", this.$refs.image.files[0]);
      }

      var images = [
        'left1', 'left2','glassware','drinkware','tableware',
      ];

      for(var i = 0, l = images.length; i < l; i++) {
        var ref_key = 'image_'+images[i];
        var field_key = 'cimg_img-'+images[i];

        if (this.$refs[ref_key].files.length) {
          form_data.append(field_key, this.$refs[ref_key].files[0]);
        }
      }

      this.loading = true;
      window.$http.put("/content/" + this.main._id, form_data).then(() => {
        this.loading = false;
        this.editing = false;
        this.load();
      });
    },
    load() {
      window.$http.get("/content?path=tailormade").then(({ data }) => {
        if (data.data.length) {
          this.main = Object.assign({}, data.data[0]);
          this.update = data.data[0];
          delete this.update._id;
          delete this.update.created_at;
          delete this.update.__v;
        }
      });
    }
  },
  mounted() {
    this.load();
  }
};
</script>
