<template>
  <div class="d-none d-lg-block">
    <ul class="nav justify-content-end small-nav">
      <li class="nav-item">
        <router-link to="/" class="nav-link">Home</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/catalogs" class="nav-link">Catalogs</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/media" class="nav-link">Media</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/about" class="nav-link">About us</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/contact" class="nav-link">Contact</router-link>
      </li>
      <li class="nav-item" @click="moveToSearch">
        <img src="../assets/icons/search.svg" class="header-search" />
      </li>
      <li class="nav-item" v-if="user && cart">
        <router-link to="/cart" class="nav-link">
          <span class="material-icons">
            shopping_cart
          </span>
          <span class="badge badge-pill badge-success">{{ cart.length || 0}}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.header-search{
  margin-top:10px;
  width:20px;
  transition-duration: 500ms;
  cursor:pointer;
  &:hover{
    transform:scale(1.1);
  }
}
.nav-item{
  a{
    font-weight:300;
    font-size:14px;
  }
}
.small-nav{
  .nav-item{
    a{
      font-size:13px;
    }
  }
}
</style>
<script>
export default {
  name: "Nav",
  props: ['user', 'cart'],
  data() {
    return {};
  },
  methods:{
    moveToSearch(){

      const possibleIds = ["started", "search-bar"];
      let domElement;
      // search all possible ids, and break if we find one!
      for(let id of possibleIds){
        domElement = document.getElementById(id);
        if(domElement) break;
      }

      if(!domElement){
        return this.$router.push({path:'/', hash: '#started'}).then(()=>{
          setTimeout(()=>{
            return this.moveToSearch();
          }, 500);

        });
      }
      const offsetTop = domElement.getBoundingClientRect().top + window.pageYOffset - 200;

      let bodyOverflowContainer = document.getElementsByTagName('main');
      if(bodyOverflowContainer.length > 0){
        bodyOverflowContainer = bodyOverflowContainer[0];
      }
      bodyOverflowContainer.scrollTop = offsetTop;
    }
  }
};
</script>
