<template>
  <div class="priorities" v-if="admin">
    <div class="container mt-3">
      <h1>Priority Tags</h1>
    </div>

    <div class="container my-4">
      <div class="card">
        <div class="card-body">
          <h5><b>Instructions:</b> You can add/edit or delete new Priority Tags, they will be added to the bottom of the list.
          When you create a new tag, or sort them you must click the  <b>Update Priorities</b> button so all products are updated correctly.
        </h5>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="text-right">
        <button @click.prevent="create" class="btn btn-outline-success">Add new Tag</button>
      </div>
    </div>
    <div class="container">
      <draggable v-model="sorted" group="priorities" @start="drag=true" @end="drag=false" class="list-group border-bottom pb-0">
        <div v-for="s in sorted" :key="s._id" class="list-group-item border border-bottom-0">
          <span>{{ s.name }}</span>
          <span class="ml-auto">
            <a @click.prevent="edit(s)" href="#" class="btn btn-sm  mb-0 btn-outline-info mr-3">
              <i class="material-icons m-0">create</i>
            </a>
            <a @click.prevent="remove(s)" href="#" class="btn btn-sm  mb-0 btn-outline-danger">
              <i class="material-icons m-0">delete</i>
            </a>
          </span>
        </div>
      </draggable>
      <div class="mt-3 text-right">
        <button v-if="sorted.length" @click.prevent="sort" class="btn btn-outline-success">Update Priorities</button>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="add-priority" data-backdrop="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Priority</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="loading" class="modal-body">
            <div class="alert alert-info">Loading...</div>
          </div>
          <form v-else @submit.prevent="save">
            <div class="modal-body">
              <div class="form-group bmd-form-group">
                <label for="category" class="bmd-label-floating">Priority Tag</label>
                <input type="text" required class="form-control" v-model="entity.name" />
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Save</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from "axios";
export default {
  name: 'priorities',
  props:['admin'],
  data(){
    return {
      tags: [],
      sorted: [],
      loading: false,
      entity: {
        name: '',
        fsorted: 0,
      }
    }
  },
  methods: {
    load() {
      window.$http
        .get("/priorities?limit=200&sort=psorted")
        .then(({ data }) => {
          this.tags = data.data;
          this.sorted = data.data.slice(0);
        });
    },
    create() {
      this.entity = {
        name: '',
        fsorted: 999
      };
      window.$('#add-priority').modal();
      window.restart();
      window.$("#add-priority input").focus();
    },
    edit(priority) {
      this.entity = priority;
      window.$('#add-priority').modal();
      window.restart();
      window.$("#add-priority input").focus();
    },
    save() {
      this.loading = true;

      var form_data = new FormData();
      for (var key in this.entity) {
        if (key != "_id") {
          form_data.append(key, this.entity[key]);
        }
      }

      var action, path;
      if (this.entity._id) {
        action = "put";
        path = "priorities/" + this.entity._id;
      } else {
        action = "post";
        path = "priorities";
      }

      window.$http[action](path, form_data).then(() => {
        window.$("#add-priority").modal("toggle");
        this.loading = false;
        this.load();
      });

    },
    remove(priority) {
      var vm = this;
      window.bootbox.confirm(
        "Are you sure you want to delete this priority tag!",
        function(result) {
          if (result) {
            window.$http.delete("/priorities/" + priority._id).then(() => {
              vm.load();
            });
          }
        }
      );
    },
    sort() {
      var sorted = [];

      for(var i = 0, l = this.sorted.length; i < l; i++) {
        sorted.push({
          pos: i,
          name:  this.sorted[i].name,
          id: this.sorted[i]._id
        })
      }

      window.$http
        .post("/priorities/sort", sorted)
        .then(({ data }) => {

        });
    }
  },
  mounted() {
    this.load();
  }
}
</script>