<template>
  <div id="products-list" class="products">
    <div v-if="started && !completed" class="products--stop text-center py-3">
      <a @click.prevent="stop" href="#" class="btn btn-outline-danger">Stop loading</a>
    </div>
    <div class="pb-5 px-3">
      <div class="row">
        <div v-for="product in products" :key="product._id" class="col-sm-12 col-md-6 col-lg-3 my-3">
          <a class="products--item"
             @click="clickedProduct(product)">
            <div class="products--item-image">
              <div
                v-if="product.glamour"
                class="products--item-overlay"
                :style="'background-image: url(' + product.glamour + ')'">
              </div>
              <img :src="product.image" :alt="product.name" class="img-fluid">
            </div>
            <h4 class="products--item-title text-center mt-3">{{ product.name }}</h4>
          </a>
        </div>
        <div v-if="!products.length && loaded" class="col-m-12 px-3">
          <div class="card-body">
            <div class="alert alert-warning">No matching records found</div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading @infinite="scroll" :identifier="si">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <div v-if="!started && !completed" class="text-center mt-3">
      <a @click.prevent="start" href="#" class="btn btn-outline-primary">Load more</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "products",
  props: ["db", "filters", "q", "flimit", "nfilter", "state"],
  watch: {
    db() {
      this.load();
    },
    q() {
      this.load();
    },
    filters() {
      this.load();
    },
  },
  data() {
    return {
      priority: 0,
      repository: "/products",
      products: [],
      sort: false,
      loaded: false,
      page: 1,
      started: false,
      completed : false,
      si: new Date(),
      scrollPos: 0
    };
  },
  computed: {
    url() {
      var urls = {
        glasses: "glassware",
        barwares: "barware",
        unbreakables: "eco",
        tablewares: "tableware",
        products: "products",
        newproducts: 'new-products',
      };

      return urls[this.db];
    },
    query() {
      var query = "db=" + this.db;
      var string = "";
      var word = "";
      for (var type in this.filters) {
        word = this.filters[type].value;
        string += "(?=.*" + type + ' ' + word + ").*";
      }

      if (this.nfilter) {
        string += "(?=.*New Launch).*"; 
      }

      if (string) {
        query += "&tag[regex]=^" + string;
      }

      if (this.q) {
        query += "&tag[regex]=" + this.q;
      }

      if (this.flimit) {
        query += "&limit=" + this.flimit;
      }

      query += "&sort=psorted,name";

      return query;
    }
  },
  methods: {
    start(){
      this.started = true;
      this.si = new Date();
    },
    stop() {
      this.started = false;
    },
    async load() {
      if (this.db && this.scrollPos === 0) {
        this.completed = false;
        this.started = false;
        this.page = 1;
        this.si = new Date();
        return await this.run(this.query);
      }
    },
    scroll($state) {

      if (!this.started) {
        $state.complete();  
        return;
      }

      this.page++;
      var query = this.query + "&page=" + this.page;
      setTimeout(() => {
        this.run(query, true, $state);
      }, 600)
    },
    async loadMultiplePages(pages){

      const limit = (this.flimit || 25) * pages;
      let query = this.query + '&limit=' + limit;
      await this.run(query, false, null);
      this.page = pages;
      this.si = new Date();
      return true;
    },
    async run(query, attach, $state) {
     return window.$http.get(this.repository + "?" + query).then(({ data }) => {
        this.loaded = true;
        var limit = this.flimit || 25;
        if (data.data.length < limit) {
          this.completed = true;
          if ($state) $state.complete();
        }

        if (attach) {
          var products = this.products.concat(data.data);
          this.products = products;
          if ($state) $state.loaded();
        } else {
          this.products = data.data;
        }
      });
    },
    clickedProduct(product){
      let doc = document.getElementsByClassName('bmd-layout-content')[0];
      let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)

      sessionStorage.setItem(this.db + '_scrollPos', top);
      sessionStorage.setItem(this.db + '_page', this.page);

      this.$router.push('/' + this.url + '/' + product._id)
    },
    fixScrollPosition(){
      let bodyOverflowContainer = document.getElementsByTagName('main');
      if(bodyOverflowContainer.length > 0){
        bodyOverflowContainer = bodyOverflowContainer[0];
      }
      bodyOverflowContainer.scrollTop = this.scrollPos;
    }
  },
  async mounted() {
    let page = this.$route.query.page  || 1;
    let scrollPos = this.$route.query.scrollPos || 0;

    if(scrollPos === 0 && this.state){
      if(this.state.page && this.state.scrollPos){
        page = this.state.page;
        scrollPos = this.state.scrollPos;
      }
    }

    this.scrollPos = parseFloat(scrollPos);

    if(this.scrollPos > 0){
      await this.loadMultiplePages(page);
      this.fixScrollPosition();
    }else{
      await this.load();
    }
  }
};
</script>

<style lang="scss">
.products--item{
  cursor:pointer;
}
.products--item-title{
  font-size:1.2rem;
  font-weight:400;
}
</style>