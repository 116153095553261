<template>
  <div class="contact">
    <div class="contact--banner">
      <img src="../assets/contact-us.jpg" class="img-fluid" />
    </div>

    <div class="container my-3">
      <div class="row py-4">
        <div class="col-md-6">
          <div class="card-body">
            <h3>We'd love to hear from you!</h3>
            <p>
              Whether you're interested in visiting one of our showrooms across
              the country, seeing a sample of one of our products, or looking to
              build a relationship, send us a message!
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-black">
            <ul class="list-group">
              <li class="list-group-item">T: (800) 869-5258</li>
              <li class="list-group-item">info@hospitalitybrandsusa.com</li>
              <li class="list-group-item">
                185 Industrial Ave, Ridgefield Park, New Jersey 07660
              </li>
            </ul>
          </div>
        </div>
      </div>
      <contact-form></contact-form>
      <div class="row py-4">
        <div class="col-md-6">
          <gmap-map></gmap-map>
        </div>
        <div class="col-md-6">
          <img src="../assets/showroom.jpeg" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GmapMap from "../components/GmapMap";
import ContactForm from "../components/ContactForm";
export default {
  name: "contact",
  components: {
    GmapMap, ContactForm
  },
  data() {
    return {

    };
  },
  computed: {},
  methods: {
  },
  mounted() {}
};
</script>
