<template>
  <div>
    <div v-if="edit" class="pt-5">
      <div class="container pt-3">
        <h3>Slide Editor</h3>
        <div class="form-group">
          <label>Slide Text</label>
          <textarea name="slide_text" class="form-control" :value="text"></textarea>
        </div>
        <div v-for="i in 3" :key="i" class="form-group">
          <label>
            Slide {{ i }}
            <a v-if="slides[i-1]" :href="slides[i-1]" target="_blank">[Current]</a>
          </label>
          <input type="file" name="slides[]" accept="image/*" class="form-control" />
        </div>
      </div>
    </div>
    <div v-show="!edit" :class="classname">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(s,k) in slides" :key="k" class="swiper-slide">
            <img :src="s" class="img-fluid" />
          </div>
        </div>
      </div>
      <div class="text-container">
        <h1 v-html="text"></h1>
        <div class="arrow-function" v-if="type == 'home'">
          <a @click.prevent="scrollTo('#section2')" href="#">
            <i class="material-icons">expand_more</i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Slider",
  props: [
    'admin', 'type', 'slides', 'edit', 'text'
  ],
  computed: {
    classname(){
      if (this.type) {
        return this.type + '--banner';
      }

      return 'banner';
    }
  },
  data() {
    return {};
  },
  watch: {
    slides() {
      this.setup();
    }
  },
  methods: {
    scrollTo(elem) {
      window.$('.bmd-layout-content').animate({
        scrollTop: window.$(elem).offset().top - 120
      })
    },
    setup(){
      new Swiper ('.swiper-container', {
        speed: 600,
        effect: 'fade',
        loop: true,
        autoplay: true
      })
    }
  },
  mounted(){
    this.setup()
  }
};
</script>
