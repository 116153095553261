<template>
  <div class="home">
    <div v-if="admin" class="editor-bar">
      <div v-if="!editing" class="crud-options">
        <a class="btn btn-outline-info mr-3" href="#" @click.prevent="editing = true" >Edit</a>
      </div>
      <div v-else-if="ploading">
        <a href="#" class="btn-outline-warning">Loading...</a>
      </div>
      <div v-else-if="editing">
        <a class="btn btn-outline-info mr-3" href="#" @click.prevent="savePage">Save</a>
        <a class="btn btn-outline-danger" href="#" @click.prevent="editing = false" >Cancel</a>
      </div>
    </div>
    <slider :admin="admin" type="home" :slides="main.content.slides" :text="main.content.slide_text" :edit="editing"></slider>
    <div class="home--tabs" id="section2">
      <ul class="nav nav-fill justify-content-center py-2">
        <li class="nav-item">
          <a data-toggle="tab" href="#started" class="nav-link active show">Let's Get Started</a>
        </li>
        <li class="nav-item">
          <a data-toggle="tab" href="#home-new" class="nav-link">New</a>
        </li>
        <li class="nav-item">
          <a data-toggle="tab" href="#tailor-made" class="nav-link">Tailor Made</a>
        </li>
        <li class="nav-item">
          <a data-toggle="tab" href="#home-eco" class="nav-link">Sustainability</a>
        </li>
      </ul>
    </div>
    <div class="home--tabs-content tab-content">
      <div id="started" class="tab-pane active">
        <div class="pt-3 px-4">
          <div class="row">
            <div class="col-12">
              <input
                type="text"
                class="form-control bg-gray search-input p-3 mb-3 search-input-home-1 search-input-home-2"
                @input="updateQ($event, 'qProducts')"
                placeholder="I'm looking for..."
              />
            </div>
            <div v-if="admin" class="col-md-12 text-right">
              <a href="#" class="btn btn-outline-success" @click.prevent="openModal('products')">Add Product</a>
            </div>
          </div>
          <div v-if="!qProducts" class="row">
            <div class="col-12 col-sm-3 mb-3">
              <div v-if="editing">
                <div class="form-group">
                  <label>Texto:</label>
                  <input type="text" class="form-control" v-model="update.content.glassware" />
                </div>
                <div class="form-group">
                  <label>Image:</label>
                  <input type="file" accept="image/*" ref="image_glassware" class="form-control" />
                </div>
              </div>
              <a v-else href="/glassware" class="category-item">
                <img :src="main.content['img-glassware']" class="img-fluid d-none d-sm-block">
                <div class="category-item-text p-2" v-html="update.content.glassware"></div>
                <div class="text-center text-uppercase border d-flex d-lg-none">
                  <span>Glassware</span>
                </div>
              </a>
            </div>
            <div class="col-12 col-sm-3 mb-3">
              <div v-if="editing">
                <div class="form-group">
                  <label>Texto:</label>
                  <input type="text" class="form-control" v-model="update.content.eco" />
                </div>
                <div class="form-group">
                  <label>Image:</label>
                  <input type="file" accept="image/*" ref="image_eco" class="form-control" />
                </div>
              </div>
              <a v-else href="/eco" class="category-item">
                <img :src="main.content['img-eco']" class="img-fluid d-none d-sm-block">
                <div class="category-item-text p-2" v-html="update.content.eco"></div>
                <div class="text-center text-uppercase border d-flex d-lg-none">
                  <span>UNBREAKABLE DRINKWARE</span>
                </div>
              </a>
            </div>
            <div class="col-12 col-sm-3 mb-3">
              <div v-if="editing">
                <div class="form-group">
                  <label>Texto:</label>
                  <input type="text" class="form-control" v-model="update.content.barware" />
                </div>
                <div class="form-group">
                  <label>Image:</label>
                  <input type="file" accept="image/*" ref="image_barware" class="form-control" />
                </div>
              </div>
              <a v-else href="/barware" class="category-item">
                <img :src="main.content['img-barware']" class="img-fluid d-none d-sm-block">
                <div class="category-item-text p-2" v-html="update.content.barware"></div>
                <div class="text-center text-uppercase border d-flex d-lg-none">
                  <span>Barware</span>
                </div>
              </a>
            </div>
            <div class="col-12 col-sm-3 mb-3">
              <div v-if="editing">
                <div class="form-group">
                  <label>Texto:</label>
                  <input type="text" class="form-control" v-model="update.content.tableware" />
                </div>
                <div class="form-group">
                  <label>Image:</label>
                  <input type="file" accept="image/*" ref="image_tableware" class="form-control" />
                </div>
              </div>
              <a v-else href="/tailormade" class="category-item">
                <img :src="main.content['img-tableware']" class="img-fluid d-none d-sm-block">
                <div class="category-item-text p-2" v-html="update.content.tableware"></div>
                <div class="text-center text-uppercase border d-flex d-lg-none">
                  <span>Tailor Made</span>
                </div>
              </a>
            </div>
          </div>
          <products :key="productsComponentKey" db="products" :q="qProducts" flimit="4"></products>
        </div>
      </div>
      <div id="home-new" class="tab-pane">
        <div class="pt-3 px-4">
          <div class="row">
            <div class="col-12">
              <input
                type="text"
                class="form-control bg-gray search-input p-3 mb-3 search-input-home-2"
                @input="updateQ($event, 'nProducts')"
                placeholder="I'm looking for..."
              />
            </div>
            <div v-if="admin" class="col-md-12 text-right">
              <a href="#" class="btn btn-outline-success" @click.prevent="openModal('newproducts')">Add Product</a>
            </div>
          </div>
          <div v-if="!nProducts" class="row">
            <div class="col-12 col-sm-3 mb-3">
              <div v-if="editing">
                <div class="form-group">
                  <label>Texto:</label>
                  <input type="text" class="form-control" v-model="update.content.new_glassware" />
                </div>
                <div class="form-group">
                  <label>Image:</label>
                  <input type="file" accept="image/*" ref="image_new-glassware" class="form-control" />
                </div>
              </div>
              <a href="/glassware#new" class="category-item">
                <img :src="main.content['img-new-glassware']" class="img-fluid d-none d-sm-block">
                <div class="category-item-text" v-html="update.content.new_glassware"></div>
                <div class="text-center text-uppercase border d-flex d-lg-none">
                  <span>Glassware</span>
                </div>
              </a>
            </div>
            <div class="col-12 col-sm-3 mb-3">
              <div v-if="editing">
                <div class="form-group">
                  <label>Texto:</label>
                  <input type="text" class="form-control" v-model="update.content.new_eco" />
                </div>
                <div class="form-group">
                  <label>Image:</label>
                  <input type="file" accept="image/*" ref="image_new-eco" class="form-control" />
                </div>
              </div>
              <a href="/eco#new" class="category-item">
                <img :src="main.content['img-new-eco']" class="img-fluid d-none d-sm-block">
                <div class="category-item-text" v-html="update.content.new_eco"></div>
                <div class="text-center text-uppercase border d-flex d-lg-none">
                  <span>UNBREAKABLE DRINKWARE</span>
                </div>
              </a>
            </div>
            <div class="col-12 col-sm-3 mb-3">
              <div v-if="editing">
                <div class="form-group">
                  <label>Texto:</label>
                  <input type="text" class="form-control" v-model="update.content.new_barware" />
                </div>
                <div class="form-group">
                  <label>Image:</label>
                  <input type="file" accept="image/*" ref="image_new-barware" class="form-control" />
                </div>
              </div>
              <a href="/barware#new" class="category-item">
                <img :src="main.content['img-new-barware']" class="img-fluid d-none d-sm-block">
                <div class="category-item-text" v-html="update.content.new_barware"></div>
                <div class="text-center text-uppercase border d-flex d-lg-none">
                  <span>Barware</span>
                </div>
              </a>
            </div>
            <div class="col-12 col-sm-3 mb-3">
              <div v-if="editing">
                <div class="form-group">
                  <label>Texto:</label>
                  <input type="text" class="form-control" v-model="update.content.new_tableware" />
                </div>
                <div class="form-group">
                  <label>Image:</label>
                  <input type="file" accept="image/*" ref="image_new-tableware" class="form-control" />
                </div>
              </div>
              <a href="/tailormade" class="category-item">
                <img :src="main.content['img-new-tableware']" class="img-fluid d-none d-sm-block">
                <div class="category-item-text" v-html="update.content.new_tableware"></div>
                <div class="text-center text-uppercase border d-flex d-lg-none">
                  <span>Tailor Made</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <products :key="productsComponentKey" db="products" :q="nProducts" flimit="4" :nfilter="true"></products>
      </div>
      <div id="tailor-made" class="tab-pane">
        <img src="../assets/tailormade.jpg" class="img-fluid" />
        <div class="content">
          <div class="card text-dark bg-light m-4">
            <div class="card-body">
              <h5 class="font-weight-light mb-4">
                We exist to inspire the success of our partners, our clients,
                and everyone of the individuals we touch with every serve.
                Cheers to your success.
              </h5>
              <router-link to="/tailormade" class="btn btn-block btn-outline-dark">Go to Tailormade</router-link>
            </div>
          </div>
        </div>
      </div>
      <div id="home-eco" class="tab-pane">
        <img src="../assets/sustain.jpg" class="img-fluid" />
        <div class="content">
          <div class="card text-white bg-dark m-4">
            <div class="card-body">
              <h5 class="font-weight-light mb-4">
                Hospitality Brands is committed to not only taking concrete
                steps to reduce its carbon footprint but leading the charge to
                bring environmentally sustainable products to the hospitality
                i5dustry. Our catalog includes 100% recyclable glass, developed
                completely sustainably, ensuring environmentally conscious
                production, without any compromise on style of quality.
              </h5>
              <router-link to="/sustainability" class="btn btn-block btn-outline-light" >Go to Sustainability</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <news :admin="admin"></news>
    <div class="modal" tabindex="-1" role="dialog" id="add-product" data-backdrop="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Product</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="loading" class="modal-body">
            <div class="alert alert-info">Loading...</div>
          </div>
          <form v-else @submit.prevent="save">
            <div class="modal-body">
              <div class="form-group bmd-form-group">
                <label for="name" class="bmd-label-floating">Name</label>
                <input type="text" required="" class="form-control" v-model="product.name"/>
              </div>

              <div class="form-group bmd-form-group">
                <label for="sku" class="bmd-label-floating">SKU</label>
                <input type="text" required="" class="form-control" v-model="product.sku"/>
              </div>

              <div class="form-group bmd-form-group">
                <label for="brand" class="bmd-label-floating">Brand</label>
                <input type="text" required="" class="form-control" v-model="product.brand"/>
              </div>

              <div class="form-group bmd-form-group">
                <label for="dimensions" class="bmd-label-floating">Dimensions</label>
                <input type="text" required="" class="form-control" v-model="product.dimensions"/>
              </div>

              <div class="form-group bmd-form-group">
                <label for="description" class="bmd-label-floating">Description</label>
                <input type="text" required="" class="form-control" v-model="product.description"/>
              </div>

              <div class="form-group bmd-form-group">
                <label for="case_pack" class="bmd-label-floating">Case pack</label>
                <input type="text" required="" class="form-control" v-model="product.case_pack"/>
              </div>

              <div class="form-group bmd-form-group">
                <label for="tag" class="bmd-label-floating">Tag</label> 
                <input type="text" required="" class="form-control" v-model="product.tag" />
              </div>

              <div class="form-group bmd-form-group">
                <label for="file">Image</label>
                <input type="file" class="form-control" accept="image/*" ref="image" />
              </div>

              <div class="form-group bmd-form-group">
                <label for="file">Glamour</label>
                <input type="file" class="form-control" accept="image/*" ref="glamour">
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Save</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Slider from "../components/Slider";
import News from "../components/News";
import Products from "../components/Products";
var qcallback = window.debounce(function($event, varq) {
  this[varq] = $event.target.value;
  this.$ls.set('hquery-'+varq, $event.target.value);
}, 100);

export default {
  name: "home",
  props: ["admin"],
  components: {
    Slider, News, Products
  },
  watch:{
    qProducts(){
      if(this.$route.query.scrollPos){
        this.productsComponentKey++;
      }
    },
    nProducts(){
      if(this.$route.query.scrollPos){
        this.productsComponentKey++;
      }
    }
  },
  data() {
    return {
      main: { content: { slides: []}},
      update: { content:{}},
      sbackground: require("../assets/home2.jpg"),
      qProducts: "",
      nProducts: "",
      db: false,
      product: {},
      editing: false,
      ploading: false,
      loading: false,
      productsComponentKey: 0
    };
  },
  methods: {
    openModal(db) {
      this.db = db;
      window.restart();
      window.$("#add-product").modal();
    },
    save() {
      var form_data = new FormData();

      for (var key in this.product) {
        form_data.append(key, this.product[key]);
      }

      if (this.$refs.image.files.length) {
        form_data.append("image", this.$refs.image.files[0]);
      }

      if (this.$refs.glamour.files.length) {
        form_data.append('glamour', this.$refs.glamour.files[0]);
      }

      this.loading = true;

      window.$http.post("/products?db=" + this.db, form_data).then(() => {
        this.loading = false;
        this.product = {};
        window.$('#add-product').modal('toggle');
      });
    },
    load() {
      window.$http.get("/content?path=home").then(({ data }) => {
        if (data.data.length) {
          this.main = Object.assign({}, data.data[0]);
          this.update = data.data[0];
          if (!this.main.content) {
            this.main.content =  {
              slides: []
            }

            this.update.content = {
              slides: []
            }
          }
          delete this.update._id;
          delete this.update.created_at;
          delete this.update.__v;
        }
      });
    },
    setup() {
      var qProducts = this.$ls.get('hquery-qProducts');
      var nProducts = this.$ls.get('hquery-nProducts');

      if (qProducts) {
        document.getElementsByClassName("search-input-home-1")[0].value = qProducts;
        this.qProducts = qProducts;
      }

      if (nProducts) {
        document.getElementsByClassName("ssearch-input-home-2")[0].value = nProducts;
        this.nProducts = nProducts;
      }
    },
    savePage() {
      var form_data = new FormData();
      var content  = this.update.content;
      content.slide_text = $('[name="slide_text"]').val();
      form_data.append('content', JSON.stringify(content));

      var images = $('[name="slides[]"]');
      for(var i = 0, l = images.length; i < l; i++) {
        if (images[i].files.length) {
          form_data.append('cimg_slides_'+i, images[i].files[0]);
        }
      }

      var images = [
        'glassware', 'eco', 'barware', 'tableware',
        'new-glassware', 'new-eco', 'new-barware', 'new-tableware'
      ];

      for(var i = 0, l = images.length; i < l; i++) {
        var ref_key = 'image_'+images[i];
        var field_key = 'cimg_img-'+images[i];

        if (this.$refs[ref_key].files.length) {
          form_data.append(field_key, this.$refs[ref_key].files[0]);
        }
      }

      this.ploading = true;
      window.$http.put("/content/" + this.main._id, form_data).then(() => {
        this.ploading = false;
        this.editing = false;
        this.load();
      });
    },
    updateQ: qcallback
  },
  mounted() {
    this.load();
    setTimeout(() => {
      this.setup();
    }, 200)
  }
};
</script>
