<template>
  <div v-if="main" class="sustainability pb-5">
    <div v-if="admin" class="editor-bar">
      <div v-if="!editing" class="crud-options">
        <a class="btn btn-outline-info mr-3" href="#" @click.prevent="editing = true">Edit</a>
      </div>
      <div v-else-if="loading">
        <a href="#" class="btn-outline-warning">Loading...</a>
      </div>
      <div v-else-if="editing">
        <a class="btn btn-outline-info mr-3" href="#" @click.prevent="save">Save</a>
        <a class="btn btn-outline-danger" href="#" @click.prevent="editing = false">Cancel</a>
      </div>
    </div>

    <div class="sustainability--banner">
      <img :src="main.banner" class="img-fluid" />
      <div v-if="editing" class="form-group container mt-3">
        <label>Banner</label>
        <input type="file" ref="image" accept="image/*" class="form-control" />
      </div>
    </div>

    <div class="container">
      <div class="row py-4">
        <div class="col-md-12">
          <div v-if="editing" class="form-group">
            <label>Title</label>
            <input type="text" class="form-control" v-model="update.content.title" />
          </div>
          <h1 v-else>"{{ update.content.title }}"</h1>
        </div>
        <div class="col-md-12">
          <div v-if="editing" class="form-group">
            <label>Description</label>
            <textarea class="form-control" v-model="update.content.description"></textarea>
          </div>
          <h3 v-else class="font-weight-light mb-3"  v-html="main.content.description"></h3>
        </div>
      </div>


      <div class="tailormade--banner">
        <div v-if="editing" class="form-group">
          <label>Image 1:</label>
          <input type="file" accept="image/*" ref="image_slide1" class="form-control" />
        </div>
        <img v-else :src="main.content['img-slide1']" class="img-fluid" />
      </div>
      <div class="row py-4">
        <div class="col-md-12">
          <div v-if="editing" class="form-group">
            <label>Description 2</label>
            <textarea class="form-control" v-model="update.content.description2"></textarea>
          </div>
          <h3 v-else class="font-weight-light mb-3"  v-html="main.content.description3"></h3>
        </div>
      </div>
      <div class="tailormade--banner">
        <div v-if="editing" class="form-group">
          <label>Image 2:</label>
          <input type="file" accept="image/*" ref="image_slide2" class="form-control" />
        </div>
        <img v-else :src="main.content['img-slide2']" class="img-fluid" />
      </div>
      <div class="row">
        <div class="col-md-12 mt-3">
          <div v-if="editing" class="form-group">
            <label>Description 3</label>
            <textarea class="form-control" v-model="update.content.description3"></textarea>
          </div>
          <h3 v-else class="font-weight-light mb-3"  v-html="main.content.description4"></h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sustainability",
  props: ["admin"],
  components: {},
  data() {
    return {
      main: false,
      editing: false,
      loading: false,
      update: false
    };
  },
  methods: {
    save() {
      var form_data = new FormData();
      for (var key in this.update) {
        if (key == "content") {
          form_data.append(key, JSON.stringify(this.update[key]));
        } else {
          form_data.append(key, this.update[key]);
        }
      }
      if (this.$refs.image.files.length) {
        form_data.append("image", this.$refs.image.files[0]);
      }

      var images = ['slide1', 'slide2'];

      for(var i = 0, l = images.length; i < l; i++) {
        var ref_key = 'image_'+images[i];
        var field_key = 'cimg_img-'+images[i];

        if (this.$refs[ref_key].files.length) {
          form_data.append(field_key, this.$refs[ref_key].files[0]);
        }
      }


      this.loading = true;
      window.$http.put("/content/" + this.main._id, form_data).then(() => {
        this.loading = false;
        this.editing = false;
        this.load();
      });
    },
    load() {
      window.$http.get("/content?path=sustainability").then(({ data }) => {
        if (data.data.length) {
          this.main = Object.assign({}, data.data[0]);
          this.update = data.data[0];
          delete this.update._id;
          delete this.update.created_at;
          delete this.update.__v;
        }
      });
    }
  },
  mounted() {
    this.load();
  }
};
</script>


