<template>
  <div class="media py-5">
    <div class="container">
      <h1>
        <a :href="instapage">@{{ username }} on instagram</a>
      </h1>
      <div class="row">
        <div v-for="g in images" :key="g.id" class="col-xs-12 col-md-4 mb-3">
          <a :href="g.media_url">
            <img :src="g.media_url" :alt="g.caption" class="img-fluid mx-auto" />
          </a>
        </div>
      </div>
      <div class="row" v-if="next_url">
        <div class="col-md-12 text-center">
          <button v-if="loading" class="btn btn-primary active">
            Loading...
          </button>
          <a v-else @click.prevent="getMoreGrams" href="#" class="btn btn-primary active">Load more</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "media",
  data() {
    return {
      access_token: "IGQVJVODlXNTI4WjJleW90X2hWMC1UT1REdG83cDZAMNFpZAOC1sbUlQQUhROUhKb0RxMXpmUHpDbW13VVoyOUFzLXJTUk1hZAlkycTB6VnJpODBqUk5WajgxbDIxcFBuWGV6MVNVelBudXdiVFhtSDIxUgZDZD",
      url: "https://graph.instagram.com/me",
      username: "",
      loading: false,
      grams: [],
      next_url: "",
      error: false
    };
  },
  computed: {
    images() {
      return this.grams.filter((gram) => { return gram.media_type == 'IMAGE'});
    },
    instapage() {
      return "https://www.instagram.com/" + this.username;
    }
  },
  methods: {
    getGrams() {
      axios
        .get(this.url+'?fields=id,username&access_token='+this.access_token)
        .then(({data}) => {
          this.username = data.username
        });

      axios
        .get(this.url + "/media?fields=id,media_type,media_url,caption&access_token=" + this.access_token)
        .then(({ data }) => {
          this.grams = data.data
          this.next_url = data.paging.next
        });
    },
    getMoreGrams() {
      this.loading = true;
      axios.get(this.next_url).then(({ data }) => {
        this.grams = this.grams.concat(data.data);
        this.next_url = data.paging.next;
        this.loading = false;
        if (!data.data.length) {
          this.next_url = false;
        }
      });
    }
  },
  mounted() {
    this.getGrams();
  }
};
</script>
