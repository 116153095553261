import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueLocalStorage from 'vue-localstorage';
import * as VueGoogleMaps from 'vue2-google-maps';
import InfiniteLoading from 'vue-infinite-loading';
import draggable from 'vuedraggable';
import axios from 'axios';
import vSelect from 'vue-select';
import VueAnalytics from 'vue-analytics';

Vue.use(InfiniteLoading, {});
Vue.component('draggable', draggable);
Vue.component('v-select', vSelect)


Vue.use(VueAnalytics, {
  id: 'UA-168890489-1',
  router
});

Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyBld_gVOd3C-MZvwQUVCBlOoQzHEkOdVvc',
		libraries: 'places', // necessary for places input
	},
});

Vue.use(VueLocalStorage, {
	name: 'ls',
	bind: true,
});

Vue.config.productionTip = false;

window.$http = axios.create({
	baseURL: 'https://api.hospitalitybrandsusa.com/api/v1',
	//baseURL: 'http://127.0.0.1:8000/api/v1', // For Local
});

var token = localStorage.getItem('token');
if (token && token != null) {
	window.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

new Vue({
	router,
	render: (h) => h(App),
}).$mount('#app');
