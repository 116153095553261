<template>
  <div class="cart py-5">
    <div v-if="user" class="container">
      <div class="row">
        <div class="col-md-6"> 
          <h2 class="text-center">Your cart</h2>
          <div class="table-responsive">
            <table class="table table-stripped">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>SKU</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p,k) in cart" :key="k">
                  <td>
                    <router-link :to="p.url">{{ p.name }}</router-link>
                  </td>
                  <td>
                    {{ p.item_code || p.unbreakable_id || p.table_id || p.glass_id || p.barware_id }}
                  </td>
                  <td>
                    <span v-if="loading"></span>
                    <a v-else href="#" @click.prevent="remove(k)">
                      <span class="material-icons text-danger">delete</span>
                    </a>
                  </td>
                </tr>
                <tr v-if="!cart || cart.length == 0">
                  <td colspan="4" class="text-center">
                    <div class="alert alert-danger" role="alert">Your cart is empty</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-6">
          <h2 class="text-center">Checkout</h2>
            <div v-if="loading" class="modal-body">
              <div class="alert alert-info">Loading...</div>
            </div>
            <form v-else @submit.prevent="doSample">              
              <div class="row">
                <div class="col-6">
                  <div class="form-group bmd-form-group">
                  <label for="first_name" class="bmd-label-floating">First name</label>
                  <input type="text" name="first_name" required="" class="form-control">
                  </div>
                  <div class="form-group bmd-form-group">
                    <label for="last_name" class="bmd-label-floating">Last name</label>
                    <input type="text" name="last_name" required="" class="form-control">
                  </div>
                  <div class="form-group bmd-form-group">
                    <label for="company_name" class="bmd-label-floating">Company name</label>
                    <input type="text" name="company_name" required="" class="form-control">
                  </div>
                  <div class="form-group bmd-form-group">
                    <label for="phone_number" class="bmd-label-floating">Phone number</label>
                    <input type="text" name="phone_number" required="" class="form-control">
                  </div>
                  <div class="form-group bmd-form-group">
                    <label for="address" class="bmd-label-floating">Address</label>
                    <input type="text" name="address" required="" class="form-control">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group bmd-form-group">
                    <label for="state" class="bmd-label-floating">State</label>
                    <input type="text" name="state" required="" class="form-control">
                  </div>
                  <div class="form-group bmd-form-group">
                    <label for="city" class="bmd-label-floating">City</label>
                    <input type="text" name="city" required="" class="form-control">
                  </div>
                  <div class="form-group bmd-form-group">
                    <label for="zipcode" class="bmd-label-floating">Zipcode</label>
                    <input type="text" name="zipcode" required="" class="form-control">
                  </div>
                  <div class="form-group bmd-form-group">
                    <label for="email" class="bmd-label-floating">Email</label>
                    <input type="email" name="email" required="" class="form-control" :value="user.email">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group bmd-form-group">
                    <label for="notes" class="bmd-label-floating">Additional notes</label>
                    <textarea class="form-control" name="notes" required=""></textarea>
                  </div>
                </div>
              </div>
              <div class="form-actions">
                <button v-if="loading" type="buttom" class="btn btn-outline-danger">
                  <span class="material-icons">hourglass_bottom</span>
                  Sending request..
                </button>
                <button v-else type="submit" :disabled="!cart || cart.length == 0" class="btn btn-outline-primary">Send</button> 
              </div>
            </form>
        </div>
      </div>
    </div>
    <div v-else class="container text-center">
      <h3>Please Sign in to see your cart</h3>
      <button class="btn btn-outline-success" @click.prevent="openLogin">Sign in </button>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "cart",
  props: ["user", "cart"],
  data() {
    return {
      loading: false,
    }
  },  
  methods: {
    openLogin() {
      window.$('#modal-login').modal();
      window.restart();
      window.$("#modal-login input").focus();
      window.$("#modal-login input").first().focus();
    },
    remove(index) {
      var cart = this.cart;
      cart.splice(index, 1);
      this.$emit('cartUpdated', cart);
      window.toastr.error('Product deleted from your cart.')
    },
    doSample($event){
      var vm = this;
      var data = window.$($event.target).serializeArray();
      data.push({ name: 'products', value: JSON.stringify(this.cart)});
      this.loading = true;
      window.$http.post('products/sample', data).then(({data}) => {
        this.loading = false;
        $event.target.reset();
        window.toastr.success('Your sample request has been sent.')
        setTimeout(function() {
          window.restart();
        }, 100);
        this.$emit('cartUpdated', []);
      });
    }
  },
  mounted() {
    window.restart();
  }
}
</script>