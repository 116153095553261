<template>
  <div v-if="db">
    <div v-if="admin" class="col-xs-12 text-right">
      <a href="#" class="btn btn-outline-success" @click.prevent="openModal(false)">Add Filter</a>
      <a href="#" class="ml-2 btn btn-outline-secondary" @click.prevent="sortCategories(false)">Sort Categories</a>
    </div>
    <div class="filters--tabs">
      <ul class="nav nav-fill justify-content-center py-2 mb-4 mb-sm-0">
        <li v-for="(filter, key) in grouped" :key="key" class="nav-item">
          <a data-toggle="tab" :href="'#filter-' + key" class="nav-link" 
          :class="{ active: show == key }" @click="show = key">{{ filter.name }}</a>
        </li>
      </ul>
    </div>
    <div class="filters--tabs-content tab-content px-sm-4">
      <div v-for="(filter, key) in grouped" :key="key" :id="'filter-' + key" class="tab-pane" :class="{ active: show == key }">
        <div class="row">
          <div v-if="admin" class="col-md-12 text-right py-3">
            <a href="#" class="btn btn-outline-secondary" @click.prevent="sortFilter(key)">Sort Filter in Categories</a>
          </div>

          <div v-for="option in filter.options" :key="option.name" class="col filter mb-3 my-sm-3">
            <a href="#" @click.prevent="setFilter(filter.name, {value: option.name, group: option.group})" class="d-block" 
            :class="{'border-success': (selected[filter.name] && selected[filter.name].value == option.name)}">
              <div class="card">
                <img v-if="option.image" :src="option.image" class="card-img-top" :alt="option.name"/>
                <div class="card-body">
                  <h5 class="card-title text-center">
                    <small>{{ option.name }}</small>
                  </h5>
                </div>
              </div>
            </a>
            <div v-if="admin" class="px-3 my-3">
              <a @click.prevent="openModal(option)" href="#" class="btn btn-outline-secondary mr-2">Edit</a>
              <a @click.prevent="remove(option)" href="#" class="btn btn-outline-danger mr-2">Remove</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="withFilters" class="filters--enable py-2 px-sm-4">
      <strong>Filters:</strong>
      <span v-for="(item, filter) in selected" :key="filter" class="badge badge-pill badge-primary m-2 py-2 px-3">
        <strong>{{ filter }}:</strong>
        {{ item.value }}
        <a href="#" @click.prevent="setFilter(filter, false)">
          <i class="material-icons small text-white">clear</i>
        </a>
      </span>
      <span class="badge badge-pill badge-danger m-2 py-2 px-3">
        <a href="#" @click.prevent="clearAll" class="text-white">
          <strong class="mr-2">Clear Filters</strong>
          <i class="material-icons">clear</i>
        </a>
      </span>
    </div>

    <div v-if="admin" class="modal" tabindex="-1" role="dialog" id="add-filter" data-backdrop="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Filter</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="loading" class="modal-body">
            <div class="alert alert-info">Loading...</div>
          </div>
          <form v-else @submit.prevent="save">
            <div class="modal-body">
              <div class="form-group bmd-form-group">
                <label for="category" class="bmd-label-floating">Category</label>
                <input type="text" required class="form-control" v-model="entity.category" />
              </div>
              <div class="form-group bmd-form-group">
                <label for="group" class="bmd-label-floating">GROUP</label>
                <input type="text" class="form-control" v-model="entity.group" />
              </div>
              <div class="form-group bmd-form-group">
                <label for="name" class="bmd-label-floating">Name</label>
                <input type="text" required class="form-control" v-model="entity.name" />
              </div>
              <div class="form-group bmd-form-group">
                <label for="file">Image</label>
                <input type="file" class="form-control" accept="image/*" ref="image" />
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Save</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="admin" class="modal" tabindex="-1" role="dialog" id="sort-categories" data-backdrop="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Sort Categories</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="scloading" class="modal-body">
            <div class="alert alert-info">Loading...</div>
          </div>
          <form v-else @submit.prevent="saveSortCategory" class="px-2">
            <div class="modal-body">
              <draggable v-model="sorted" group="categories" @start="drag=true" @end="drag=false" class="list-group border-bottom pb-0">
                <div v-for="filter in sorted" :key="filter.name" class="list-group-item border border-bottom-0">{{ filter.name }}</div>
              </draggable>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Save</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="admin" class="modal" tabindex="-1" role="dialog" id="sort-filters" data-backdrop="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content" v-if="sfindex !== false">
          <div class="modal-header">
            <h5 class="modal-title">Sort Filters</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="sfloading" class="modal-body">
            <div class="alert alert-info">Loading...</div>
          </div>
          <form v-else @submit.prevent="saveSortFilter" class="px-2">
            <div class="modal-body">
              <draggable v-model="sorted[sfindex].options" group="categories" @start="drag=true" @end="drag=false" class="list-group border-bottom pb-0">
                <div v-for="option in sorted[sfindex].options" :key="option._id" class="list-group-item border border-bottom-0">{{ option.name }}</div>
              </draggable>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Save</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "filters",
  props: ["db", "admin"],
  watch: {
    db() {
      this.setup();
      this.load();
    }
  },
  data() {
    return {
      loading: false,
      scloading: false,
      sfloading: false,
      filters: [],
      selected: {},
      entity: false,
      show: 0,
      grouped: [],
      sfindex: false,
      sorted: [],
    };
  },
  computed: {
    withFilters() {
      for (var item in this.selected) {
        return true;
      }
      return false;
    }
  },
  methods: {
    openModal(filter) {
      if (filter) {
        this.entity = Object.assign({}, filter);
        delete this.entity.createdAt;
      } else {
        this.entity = {
          name: "",
          category: "",
          group: "",
          type: this.db
        };
      }
      window.$("#add-filter").modal();
      window.restart();
      window.$("#add-filter input").focus();
    },
    save() {
      this.loading = true;

      var form_data = new FormData();
      for (var key in this.entity) {
        if (key != "_id") {
          form_data.append(key, this.entity[key]);
        }
      }
      if (this.$refs.image.files.length) {
        form_data.append("image", this.$refs.image.files[0]);
      }

      var action, path;
      if (this.entity._id) {
        action = "put";
        path = "filter/" + this.entity._id;
      } else {
        action = "post";
        path = "filter";
      }

      window.$http[action](path, form_data).then(() => {
        window.$("#add-filter").modal("toggle");
        this.loading = false;
        this.load();
      });
    },
    remove(filter) {
      var vm = this;
      window.bootbox.confirm(
        "Are you sure you want to delete this filter!",
        function(result) {
          if (result) {
            window.$http.delete("/filter/" + filter._id).then(() => {
              vm.load();
            });
          }
        }
      );
    },
    setup() {
      this.show = 0;
      var selected = this.$ls.get('filters-'+this.db);
      var query = this.$ls.get('query-'+this.db) || '';

      if (selected || query) {
        this.selected = JSON.parse(selected || '{}');
        this.$emit("updatefilter", this.selected, query);
      } else {
        this.selected = {};
      }
    },
    setFilter(filter, item) {
      var nobject = Object.assign({}, this.selected);

      if (item && ( !this.selected[filter] || (this.selected[filter].value != item.value))) {

        var nobject = Object.assign({}, this.selected);

        if (item.group) {
          for(var i in nobject) {
            if (nobject[i].group == item.group) {
              delete nobject[i];
            }
          }
        }

        nobject[filter] = item;
        this.selected = nobject;
      } else {
        delete nobject[filter];
        this.selected = nobject;
      }

      this.$ls.set('filters-'+this.db, JSON.stringify(this.selected));
      this.$emit("updatefilter", this.selected, false);
    },
    clearAll() {
      this.selected = {};
      this.$ls.set('filters-'+this.db, JSON.stringify(this.selected));
      this.$emit("updatefilter", this.selected, false);
    },
    load() {
      window.$http
        .get("/filter?limit=200&sort=csorted,fsorted,name&type=" + this.db)
        .then(({ data }) => {
          this.filters = data.data;
          this.setGrouped();
        });
    },
    setGrouped() {
      var grouped = [];
      var indexes = {};
      var x = 0;
      if (this.filters.length) {
        for (var i in this.filters) {
          var filter = this.filters[i];
          var ct = filter.category.toUpperCase();
          if (typeof indexes[ct] == "undefined") {
            indexes[ct] = x;
            grouped.push({
              name: ct,
              options: []
            });
            x++;
          }
          grouped[indexes[ct]].options.push(filter);
        }
      }
      this.grouped = grouped;
      this.sorted = JSON.parse(JSON.stringify(grouped));

      if(window.location.hash == '#new') {
        setTimeout(function(){
          window.$('[href="#filter-1"]').click();
        }, 300)
      }
    },
    sortCategories() {
      window.$("#sort-categories").modal("toggle");
    },
    saveSortCategory() {
      this.scloading = true;

      var sorted = [];

      for(var i = 0, l = this.sorted.length; i < l; i++) {
        sorted.push({
          pos: i,
          name: this.sorted[i].name
        })
      }

      window.$http
        .post("/filter/sort/categories?type=" + this.db, sorted)
        .then(({ data }) => {
          this.scloading = false;
          window.$("#sort-categories").modal("toggle");
          this.load();
        });
    },
    sortFilter(index) {
      this.sfindex = index;
      window.$("#sort-filters").modal("toggle");
    },
    saveSortFilter() {
      this.sfloading = true;
      var sorted = [];

      var filters = this.sorted[this.sfindex]['options'];

      for(var i = 0, l = filters.length; i < l; i++) {
        sorted.push({
          pos: i,
          id: filters[i]._id
        })
      }

      window.$http
        .post("/filter/sort/filters", sorted)
        .then(({ data }) => {
          this.sfloading = false;
          window.$("#sort-filters").modal("toggle");
          this.load();
        });
    }
  },
  mounted() {
    this.setup();
    this.load();
  }
};
</script>