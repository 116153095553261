<template>
  <div class="bmd-layout-container bmd-drawer-f-l" ref="main">
    <header class="bmd-layout-header fixed-top">
      <div class="navbar navbar-light bg-white">
        <button class="navbar-toggler" type="button" data-toggle="drawer" data-target="#dw-s1">
          <span class="sr-only">Toggle drawer</span>
          <i class="material-icons">menu</i>
        </button>
        <router-link to="/" class="navbar-brand ml-2 mr-auto">
          <img src="./assets/hos.png" class="img-fluid" />
        </router-link>
        <app-nav :cart="cart" :user="user"></app-nav>
        <div class="sub-menu">
          <div class="container d-none d-lg-block py-2">
            <ul class="nav nav-fill justify-content-center">
              <li class="nav-item">
                <router-link to="/glassware" class="nav-link">Glassware</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/eco" class="nav-link">Unbreakable Drinkware</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/barware" class="nav-link">Barware</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/tailormade" class="nav-link">Tailor Made</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <div id="dw-s1" class="bmd-layout-drawer bg-white">
      <app-drawer :user="user" :admin="admin" @close-draw="closeDraw" @logout="logout"></app-drawer>
    </div>
    <main class="bmd-layout-content" ref="container">
      <router-view :admin="admin" :user="user" :cart="cart" @cartUpdated="cartUpdated"></router-view>
      <app-footer></app-footer>
    </main>

    <div class="modal" tabindex="-1" role="dialog" id="modal-login" data-backdrop="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Login</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="loading" class="modal-body">
            <div class="alert alert-info">Loading...</div>
          </div>
          <form v-else @submit.prevent="doLogin">
            <div class="modal-body">
              <div v-if="message" class="alert alert-danger">{{ message }}</div>
              <div class="form-group bmd-form-group">
                <label for="email" class="bmd-label-floating">Email</label>
                <input type="email" class="form-control" v-model="login.email" id="email" required autocomplete="off" autofill="off"/>
              </div>
              <div class="form-group bmd-form-group">
                <label for="password" class="bmd-label-floating">Password</label>
                <input type="password" class="form-control" v-model="login.password" id="password" required autocomplete="off" autofill="off"/>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-outline-primary">Login</button>
              <button type="button" @click.prevent="showSignup" class="ml-2 btn btn-outline-secondary">Sign up</button>
              <button type="button" @click.prevent="showForgot" class="ml-2 btn btn-outline-secondary">Forgot Password</button>
              <button type="button" class="ml-auto btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="modal-signup" data-backdrop="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">New Account</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="loading" class="modal-body">
            <div class="alert alert-info">Loading...</div>
          </div>
          <form v-else @submit.prevent="doSignup">
            <div class="modal-body">
              <div v-if="message" class="alert alert-danger">{{ message }}</div>
              <div class="form-group bmd-form-group">
                <label for="s-name" class="bmd-label-floating">Full name</label>
                <input type="text" class="form-control" v-model="account.name" id="s-name" required autocomplete="off" autofill="off"/>
              </div>
              <div class="form-group bmd-form-group">
                <label for="s-email" class="bmd-label-floating">Email</label>
                <input type="email" class="form-control" v-model="account.email" id="s-email" required autocomplete="off" autofill="off"/>
              </div>
              <div class="form-group bmd-form-group">
                <label for="s-password" class="bmd-label-floating">Password</label>
                <input type="password" class="form-control" v-model="account.password" id="s-password" minlength="8" required autocomplete="off" autofill="off"/>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-outline-primary">Sign up</button>
              <button type="button" class="ml-auto btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="modal-forgot" data-backdrop="false">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Reset Password</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="loading" class="modal-body">
            <div class="alert alert-info">Loading...</div>
          </div>
          <form v-else @submit.prevent="doForgot">
            <div class="modal-body">
              <div v-if="message" class="alert alert-danger">{{ message }}</div>
              <div class="form-group bmd-form-group">
                <label for="f-email" class="bmd-label-floating">Email</label>
                <input type="email" class="form-control" v-model="forgot.email" id="f-email" required autocomplete="off" autofill="off"/>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-outline-primary">Submit</button>
              <button type="button" class="ml-auto btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="scss">
@font-face {
  font-family: 'Bebas Neue';
  src: url('/assets/fonts/bebas-neue-thin.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('/assets/fonts/bebas-neue-thin.otf');
  font-weight: 300;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('/assets/fonts/bebas-neue-bold.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('/assets/fonts/bebas-neue-bold.otf');
  font-weight: 700;
}

html {
  scroll-behavior: smooth;
}
body{
  font-family:'Bebas Neue', sans-serif!important;
}
h3{
  font-size:1.3rem;
}
</style>
<script>
import AppDrawer from "./components/Drawer"
import AppNav from "./components/Nav";
import AppFooter from "./components/Footer"
export default {
  components: {
AppDrawer, AppNav, AppFooter
  },
  watch: {
    $route() {
      this.closeDraw();
      window.restart();
    }
  },
  data() {
    return {
      user: false,
      cart: false,
      account: {
        name: '',
        email:'',
        password: '',
      },
      loading: false,
      message: false,
      login: {
        email: "",
        password: ""
      },
      forgot: {
        email: "",
      },
    };
  },
  computed: {
    admin() {
      if (this.user && (this.user.role == 'admin' || this.user.role == 'publisher')) {
        return true;
      }
      return false;
    }
  },
  methods: {
    closeDraw() {
      this.$refs["main"].classList.remove("bmd-drawer-in");
      this.$refs["container"].scrollTo(0, 0);
    },
    showSignup() {
      this.loading = false;
      this.message = false;
      window.$('#modal-login').modal('hide');
      window.$('#modal-signup').modal();
      window.restart();
      window.$("#modal-signup input").first().focus();
    },
    showForgot() {
      this.loading = false;
      this.message = false;
      window.$('#modal-login').modal('hide');
      window.$('#modal-forgot').modal();
      window.restart();
      window.$("#modal-forgot input").first().focus();
    },
    doForgot() {
      this.loading = true;
      window.$http.post("/auth/forgotpassword", this.forgot).then(response => {
        this.loading = false;
        if (response.data.success) {
          window.$("#modal-forgot").modal("hide");
          this.forgot = {
            email:'',
          }
          window.toastr.info('You should receive an email with the next steps.')
        } else {
          setTimeout(function() {
            window.restart();
          }, 100);
          this.message = response.data.error;
        }
      });
    },
    doSignup() {
      this.loading = true;
       window.$http.post("/auth/register", this.account).then(response => {
        this.loading = false;
        if (response.data.success) {
          window.$("#modal-signup").modal("hide");
          this.account = {
            name: '',
            email:'',
            password: '',
          }
          this.setupUser(response.data.token);
          window.toastr.info('Your account has been created')
        } else {
          setTimeout(function() {
            window.restart();
          }, 100);
          this.message = response.data.error;
        }
      });
    },
    doLogin() {
      this.loading = true;
      window.$http.post("/auth/login", this.login).then(response => {
        this.loading = false;
        setTimeout(function() {
          window.restart();
        }, 300);

        if (response.data.success) {
          window.$("#modal-login").modal("hide");
          this.login = {
            email: "",
            password: ""
          }
          this.setupUser(response.data.token);
          window.toastr.info('Login successfully')
        } else {
          this.message = 'Invalid username/password. Please try again.';
          this.$ls.set("token", false);
        }
      });
    },
    logout() {
      this.$ls.set("token", false);
      this.user = false;
    },
    setupUser(token) {
      this.$ls.set("token", token);
      window.$http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      this.loadUser();
    },
    loadUser() {
      window.$http.get("/auth/me").then(response => {
        this.user = response.data.data;
      });
    },
    cartUpdated(current) {
      if (current) {
        this.cart = current;
        this.$ls.set('cart', JSON.stringify(current));
      } else {
        const cart = this.$ls.get('cart');
        if (cart) {
          this.cart = JSON.parse(cart);;
        }
      }
    }
  },
  mounted() {
    var token = this.$ls.get("token");
    window.localStorage.clear()
    this.$ls.set("token", token);

    if (token && token != 'false') {
      this.loadUser();
      this.cartUpdated();
    }
  }
};
</script>
